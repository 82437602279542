.game-theme {
 .headerbar{
    position: fixed !important;
    @include mobile() {
        position: sticky !important;
    }
    width: 100%;
    top: 0;
    z-index: 999;
 }   
}
.headerbar {
    display: flex;
    align-items: center;
    height: 60px;
    .coins {
        position: absolute;
        right: 10px
    }

    &.horizontal {
        .nav-container {
            margin: auto;
            display: flex;
            justify-content: center;
            gap: 30px;
        }
        .right-align {
            position: absolute;
            right: 10px;
        }
    }
}