.notifications {
    position: fixed;
    top: 0;
    right: 0;
    background-color: #222222;
    z-index: 99;
    height: 100vh;
    width: 80%;
    max-width: 800px;
    overflow: auto;
    transition: 0.5s;
    padding-bottom: 100px;

    &.open {
        right: 0;
    }

    &.close {
        right: -100%
    }
}