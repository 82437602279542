.chat-screen {
    width: 500px;
    max-width: 90%;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 10;
    @include mobile() {
        bottom: 70px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.friend-info {
    display: flex;
    align-items: center;
}

.friend-profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.friend-details {
    display: flex;
    flex-direction: column;
}

.friend-name {
    font-weight: bold;
    font-size: 16px;
}

.friend-address {
    font-size: 14px;
    color: #666666;
}

.close-btn {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    color: #666666;
}

.close-btn:hover {
    color: #333333;
}

.messages-container {
    // height:350px !important;
    height: calc(var(--vh) - 300px);
    overflow-y: auto;
    padding: 10px;
}

.message {
    max-width: 55%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
}

.sent {
    background-color: #171717;
    color: #ffffff;
    align-self: flex-end !important;
    margin-left: auto;
    .indicator {
        text-shadow: 6px 0px;
    }
    .indicator.seen {
        color: #00eaff;
    }
    .timing {
        color: #0dcaf0;
    }
}

.received {
    background-color: #3d9fc4;
    color: #ffffff;
    .timing {
        color: #ffec06;
    }
}

.message-content {
    word-wrap: break-word;
}

.input-container {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    border-top: 1px solid #e0e0e0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.message-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    outline: none;
}

.send-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
    outline: none;
}

.send-button:hover {
    background-color: #0056b3;
}
