body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* gameUser_heading starts */
.gameUser_form_label {
  font-style: normal;
  font-size: 14px;
  color: #000000;
  padding-top: 8px;
}

.custom-border {
  border: 1px solid #ccc !important;
}

@media (max-width: 640px) {
  .custom-border {
    border: none !important;
  }
}
.fs-8{
  font-size: 8px;
}