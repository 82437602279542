@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
.bg-light {
  background-color: #dddddd;
}

.dim-light-bg {
  background-color: #a6b1e1;
}

.bg-dark {
  background-color: #424874;
}

.clr-white {
  color: white;
}

.clr-reg {
  color: #2d2d2d;
}

.clr-dark {
  color: #000000;
}

.clr-err {
  color: #f36c6c;
}

.clr-light {
  color: #6b6b6b;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-10 {
  padding: 10px;
}

.ph-30 {
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 650px) {
  .ph-md-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
}
input, textarea {
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  outline: none;
  width: 100%;
}

label.required::after {
  content: "*";
  color: red;
  margin-left: 3px;
}

.input-group {
  margin-bottom: 10px;
}

.flex-box {
  display: flex;
}
.flex-box.column {
  flex-direction: column;
}
.flex-box.justify.center {
  justify-content: center;
}
.flex-box.justify.end {
  justify-content: end;
}
.flex-box.between {
  justify-content: space-between;
}
.flex-box.align.center {
  align-items: center;
}

@media (max-width: 650px) {
  .m-container {
    max-width: 90%;
    margin: auto;
  }
}
@media (min-width: 650px) {
  .m-container {
    max-width: 1200px;
    margin: auto;
    padding: 10px 30px;
  }
}

button, .btn {
  padding: 10px 20px;
  cursor: pointer;
}
button.main, .btn.main {
  background-color: #1cb8ff;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}
button.outline, .btn.outline {
  background-color: unset;
  border: 1px solid #1cb8ff;
}
button.rounded, .btn.rounded {
  border-radius: 5px;
}

.shadow-bottom-reg {
  box-shadow: 0 2px 4px 0px #d2d2d2;
}

.border-box {
  border: 1px solid #b8b8b8;
  border-radius: 5px;
}

.rounded {
  border-radius: 5px;
}

.rounded-md {
  border-radius: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mt-10 {
  margin-top: 10px;
}

a {
  text-decoration: none;
  color: #2a97c2;
}

img {
  max-width: 100%;
  max-height: 100%;
}
img.w-full {
  width: 100%;
}
img.w-full.cover {
  object-fit: cover;
}

.absolute {
  position: absolute;
}

.inline-block {
  display: inline-block;
}

.mr-css {
  font-family: "Roboto", sans-serif;
}

.blog-layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px 20px;
  grid-template-areas: "item1 item1 item1" "item2 item3 item4" "item5 item5 item5";
}
.blog-layout .item1 {
  grid-area: item1;
}
.blog-layout .item2 {
  grid-area: item2;
}
.blog-layout .item2 .content-item {
  flex-direction: column;
}
.blog-layout .item2 .content-item img {
  width: 100% !important;
}
.blog-layout .item3 {
  grid-area: item3;
}
.blog-layout .item3 .content-item {
  flex-direction: column;
}
.blog-layout .item3 .content-item img {
  width: 100% !important;
}
.blog-layout .item4 {
  grid-area: item4;
}
.blog-layout .item4 .content-item {
  flex-direction: column;
}

.blog-layout   .content-item .description {
  /* flex-direction: column; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}
.blog-layout .item4 .content-item img {
  width: 100% !important;
}
.blog-layout .item5 {
  grid-area: item5;
}
.blog-layout > :nth-child(n+5) {
  grid-column: span 3;
}
.blog-layout .blog-item {
  height: 100%;
}
@media (max-width: 650px) {
  .blog-layout {
    display: block;
  }
  .blog-layout .content-item {
    flex-direction: column;
  }
  .blog-layout .content-item img {
    width: 100% !important;
  }
}

* {
  box-sizing: border-box;
}

