$headerHeight: 65px;
@import '_break-points';
@import './popup';
@import './flick-scroller';
@import './navigation';
@import './content';
@import './flick-drawer';
@import './colors';
@import './tabs';
@import './height';
@import './fonts';
@import './buttons';
@import './chat';
@import './icons';
@import './transition';
@import './header';
@import './mr-altert';
@import './counter-timer';
@import './profile';
@import './loading';
@import './back-btn-title';
@import './splash';
@import './shadows';
@import './daily-bonus';
@import './leaderboard';
@import './notifications';
@import './footer';
@import './horizontal-navbar';
@import './user_info';
// @import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gudea:ital,wght@0,400;0,700;1,400&family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Mulish:ital@0;1&family=Odibee+Sans&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');


$backgroundImg: 'img/main-bg-5.jpeg';

html,
body {
    overscroll-behavior: none;
}

body {
    // font-family: "Roboto Slab", serif;

    // color: white;
    .game-theme {
        // background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
    }

    @media (max-width:700px) {

        // background: url('./../img/main-bg-4.jpeg');
    }

    #root {
        // background: linear-gradient(180deg, rgb(61 76 161 / 91%), rgb(0 0 0 / 84%));
        // min-height: 100vh;
        min-height: calc(var(--vh) - $headerHeight);
    }

    a {
        text-decoration: none;
        color: unset;
    }
}

.moplay {
    @include Popup();

    img {
        max-width: 100%;
        max-height: 100%;
    }

    .title-reg {
        // font-family: "Odibee Sans", sans-serif;
        font-family: "Roboto Slab", serif;
        font-size: 20px;
        // color: #ff9e00;
        color: white;
    }

    .scroll-container {
        height: calc(var(--vh));
        overflow: auto;
        overflow-x: hidden;
    }

    .moplay-header {
        height: $headerHeight;
    }

    .navigation-arrow-btn {
        width: 50px;
        height: 50px;
        background-color: lightgray;
        padding: 10px;
        border-radius: 50%;
        cursor: pointer;
    }

    .mp-game-video-progress-bar {
        position: absolute;
        top: auto;
        left: 0;
        bottom: 80px;
        width: 100%;
        height: 3px;
        background-color: #ccc;
    }

    .mp-game-promo-video {
        height: 100%;
        background-color: #c30303;
    }

    .moplay-user-profile-image {
        width: 200px;
        height: 200px;
    }

    .mp-cursor-pointer {
        cursor: pointer;
    }

    .mp-cursor-pointer-not-allowed {
        cursor: not-allowed;
    }

    .mp-camera-icon {
        padding: 6px;
        bottom: 3px;
    }

    .mp-camera-icon-position {
        bottom: 20px;
        right: 15px;
    }

    .mp-user-info-spinner-position {
        bottom: 225px;
        left: 47%
    }

    .mp-scroll-bar-width {
        @include mobile() {
            scrollbar-width: none;
        }
    }

    .mp-friend-profile-img {
        width: 40px;
        height: 40px;
    }

    .moplay-game-icon-image {
        max-width: 100%;
        border-radius: 15px;
        height: 150px;
        object-fit: contain;
    }

    .moplay-play-game {
        height: var(--vh);
        display: flex;
        flex-direction: column;
        height: -webkit-fill-available;
    }

    .mp-mt-50 {
        margin-top: 50px;
    }

    .mp-game-list-promo-video {
        margin-top: 0px;
        width: 250px;
        height: 30%;
        border-radius: 20px;
    }

    .moplay-promo-video-play-button-position {
        left: 50%;
        transform: translateX(-50%);
    }

    .mp-app-icon {
        border-radius: 50%;
        object-fit: cover;
        width: 22px;
        height: 22px;
    }

    .pb-50 {
        padding-bottom: 50px;
    }

    .mp-scroll-width-none {
        scrollbar-width: none !important;
    }

    .pointer {
        cursor: pointer;
    }

    .games-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;

        @include tab() {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @include mobile() {
            grid-template-columns: 1fr 1fr;
        }
    }
}

// background-image: linear-gradient(-20deg, #6e45e2 0%, #88d3ce 100%);
//     background-image: linear-gradient(45deg, #8baaaa 0%, #ae8b9c 100%);
//     background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
//     /* background-image: linear-gradient(-20deg, #ddd6f3 0%, #faaca8 100%, #faaca8 100%); */
//     background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
//     background-image: linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%);
//     background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);