$xsBreakpoint: 576px;
$smBreakpoint: 768px;
$mdBreakpoint: 1024px;
$lgBreakpoint: 1290px;
$xlBreakpoint: 1440px;

// @mixin xs-breakpoint {
//     @media only screen and (max-width: $xsBreakpoint) {
//         @content;
//     }
// }

// @mixin sm-breakpoint {
//     @media only screen and (min-width: $smBreakpoint) {
//         @content;
//     }
// }

// @mixin md-breakpoint {
//     @media only screen and (min-width: $mdBreakpoint) {
//         @content;
//     }
// }

// @mixin lg-breakpoint {
//     @media only screen and (min-width: $lgBreakpoint) {
//         @content;
//     }
// }

// @mixin xl-breakpoint {
//     @media only screen and (min-width: $xlBreakpoint) {
//         @content;
//     }
// }

@mixin desktop {
    @media (min-width:1024px) {
        @content;
    }
}
@mixin tab {
    @media (max-width:1024px) {
        @content;
    }
}
@mixin mobile {
    @media (max-width:768px) {
        @content;
    }
}