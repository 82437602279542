.game-theme {
    .section-content {
        // margin-left: 300px;
        margin-top: 60px;
        width: 100%;
        .mp-game {
            // flex:1;
            border: none;
            height: calc(var(--vh) - 55px);
        }
    
        &.no-profile {
            margin-left: unset;
            width: 100%;

        }

        &.no-header {
            margin-top: unset;
            .mp-game {
                height: calc(var(--vh) - 55px);
            }
                    
        }
        @include mobile() {
            margin-left: unset;
            width: 100%;
            margin-top: unset;
        }
    }
}

.section-content {
    position: relative;



    @include mobile() {
        margin-left: 0;
        min-height: calc(100vh - 10px);
    }
}