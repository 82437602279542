.section-navigation {
    width: 300px;

}

.mobile-navbar {
    bottom: 5px;
    height: 65px;
    // border-top-right-radius: 45px;
    // border-top-left-radius: 45px;
    border-radius: 15px;
    padding: 10px 20px;
    background-color: white;
    box-shadow: #dedcdb -1px 0px 10px 1px;

    a {
        // position: relative;
        z-index: 5;
        // color: #004071;
        color:#aabdcb;
    }

    a.active, div.active {
        // color: #ff9e00;
        color: #de0504;
    }

    a::after {
        content: '';
        bottom: 10px;
        left: 35px;
        width: 0px;
        height: 5px;
        background: #004071;
        position: absolute;
        left: -15px;
        bottom: -10px;
        transition: 0.25s;
        transform-origin: 0%;
    }

    a:hover {
        color: #de0504;
    }

    a.active::after {
        width: calc(100% + 30px);
    }
}

.nav-tabs {
    --bs-nav-tabs-link-active-bg: #ff9e00;
    --bs-nav-tabs-link-active-color: #ffffff;

    .nav-link.active {
        border: none;
        transform: translate(0, 1px);
    }
}