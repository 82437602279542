$corn-color: #002643;
$lemon-color: #ff9e00;
$skyler-color: #3fbdf8;

.moplay-primary-bg {
    background-color: #0a4ca0;
}

.moplay-primary-color {
    color: #0a4ca0;
}

.moplay-secondary-bg {
    background-color: #07070787;
}

.moplay-confident-bg {
    background-image: linear-gradient(to bottom, #ffffff 0%, #002643 100%)
}

.moplay-light-blood-bg {
    background-image: linear-gradient(180deg, #ffffff 75%, #ffc6c6 100%);
}

.moplay-corn-bg {
    // background-color: #002643;
    // background-color: #D9D9D9;
    background-color: #EFF1F2;

}

.moplay-lemon-bg {
    background-color: #ff9e00;
}

.moplay-yellow-clr {
    color: $lemon-color;
}

.moplay-lemon-clr {
    // color: #ff9e00;
    color: white;
}

.mp-text-main-red {
    color: #DA0707;
}

.mp-text-clr {
    color: #244055;
}

.border-bottom {
    border-bottom: 1px solid #BBC9D3 !important;
}

.moplay-red-bg {
    background-color: #0758da;
}

.mp-light-gray-bg {
    background-color: #bbc9d380;
}

.mp-popup-border-color {
    border: 4px solid #BDDEF6CC !important;
}

.form-control {
    // background: #C6CAD0 !important;
    border: 1px solid #CFD2D8 !important;
    box-shadow: 0px 2px 2px 0px #9FABBC inset !important;
}

.bg-light {
    background-color: #eef1f2 !important;
}

.bg-gradient-blue {
    background-color: #0C2575;
}

.bg-gradient-white {
    background: linear-gradient(180deg, #C6CAD6, #E5EBFF, #BBBDC3)
}