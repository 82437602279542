.footer {
    // margin-left: 300px;


    @include mobile() {
        margin-left: unset;
    }
}

.no-profile {
    .footer {
        margin-left: unset;
        width: 100%;
    }

}