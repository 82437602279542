.f-10 {
    font-size: 10px;
}

.f-14 {
    font-size: 14px;
}

.f-18 {
    font-size: 18px;
}

.f-20 {
    font-size: 20px;
}

.f-25 {
    font-size: 25px;
}

.f-45 {
    font-size: 45px !important;
}

.wix-text {
    font-family: "Wix Madefor Display", sans-serif;
}

.game-theme {
    &.wix-text {
        font-family: "Itim", cursive;
    }
}