.flick-scroller {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .video-shot {
        position: absolute;
        // visibility: hidden;
        height: 100%;
        width: 100%;

        .shot-player {
            max-width: 700px;
            margin: auto;
            height: calc(100vh - $headerHeight - 25px);
            background-color: #000000;
            // max-width: 480px;
            margin-top: 10px;
            margin-bottom: 10px;
            border-radius: 20px;

            @include mobile() {
                margin-top: 0px;
                margin-bottom: 0px;
                height: 100%;
            }

            .video-info {
                bottom: 0px;

                @include mobile() {
                    bottom: 80px;
                }
            }

            .shot-video {
                height: 90%;
                width: 100%;
                object-fit: cover;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;


                @include mobile() {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                }
            }

            .action-btn-container {
                background: #43434378;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
            }

            .action-btn {
                width: 50px;
                height: 50px;
                // background-color: lightgray;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 20px;
            }
        }

        &.smooth {
            transition: 0.2s;
        }

        .ad-slot {
            background: black;
            color: white;
            flex-direction: column;

            a {
                user-select: none;
                -webkit-user-drag: none;
                width: 75%;
                height: 70%;

                iframe {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &.smooth {
        .video-shot {
            transition: 0.2s;
        }
    }
}