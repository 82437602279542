.transition-500ms {
    transition: 0.5s;
}

.idle {
    left: 0;
}

.to-left {
    transform: translate(-110%, 0);
    // left: -100%;
    height: 0;
}

.to-right {
    transform: translate(110%, 0);
    // left: 100%;
    height: 0;
}

.to-bottom {
    transform: translate(0, 120%);
}

.anim-left-to-right {
    animation: LeftToRight 0.75s;
}

.heartbeat {
    animation: HeartBeatAnim 0.25s;
    transition: 0.5s;
}

@keyframes LeftToRight {
    0% {
        transform: translate(-110%, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

.anim-right-to-left {
    animation: RightToLeft 0.75s;
}

@keyframes RightToLeft {
    0% {
        transform: translate(110%, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes HeartBeatAnim {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}