.game-theme {
    .profile {
        background-color: unset;
        position: fixed;
        width: 300px;
        left: 0;
        height: calc(100vh - 60px);
        // top: 60px;

        @include mobile() {
        }
        left: -100%;
    }
}

#profile_toggler_checkbox:checked+.profile {
    left: 0;
}

.profile {
    position: fixed;
    top: 0;
    left: -100%;
    background-color: rgb(46, 46, 46);
    padding-bottom: 100px;
    overflow-y: auto;

    @include mobile() {
        height: 100vh;
    }

    z-index: 1111;
    // &.close {
    //     left: -100%;
    // }
    // &.open {
    //     left: 0;
    // }
    transition: 0.25s;

    .options {
        list-style-type: none;
        padding-left: 0;
        padding-top: 30px;

        a {

            text-decoration: none;

            &.active {
                li {
                    color: white;
                    background-color: #ff9e00;
                }
            }
        }

        li {
            background-color: white;
            color: black;
            padding: 10px 30px;
            border-radius: 10px;
            margin-bottom: 15px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            // .indicator {
            //     position: absolute;
            //     right: 10px
            // }
        }
    }

    .close-btn {
        position: absolute;
        padding: 15px;
        right: 0;
        cursor: pointer;
    }

    &.no-header {
        top: unset;
    }
}