.mp-publisher-profile-icon {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.mp-publisher-banner-image {
    width: 100%;
    border-radius: 10px;
    height: 150px;
    object-fit: cover;
}

.mp-user-profile-icon {
    width: 35px;
    height: 35px;
}

.mp-shop-image {
    max-width: 100%;
    object-fit: contain;
    height: 100px;
}

.mp-notification-profile-img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
}

.mp-header-logo-image {
    object-fit: contain !important;
}

.mp-game-icon {
    height: 300px;
    // width: 100px;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    // border-radius: 15px;
    object-fit: cover !important;
    position: relative;
    @include mobile {
        height: 200px;
    }
}
.game-card-title {
    // position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    .description {
        display: -webkit-box; /* Required for line clamping */
        -webkit-box-orient: vertical; /* Set orientation to vertical */
        overflow: hidden; /* Hide overflowing text */
        -webkit-line-clamp: 3; /* Limit text to 3 lines */
        text-overflow: ellipsis; /* Add ellipsis for overflow */
    }
}

.mp-recent-games-image{
    height:130px !important;
}

.mp-game-result-profile-icon {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    box-shadow: inset;

    @include mobile {
        height: 100px;
        width: 100px;
    }
}