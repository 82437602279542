.splash {
    height: 100vh;
    position: fixed;
    z-index: 999;
    width: 100%;
    background-color: #43f5ff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #f3fbff, #9bc0e6);
    img {
        animation: logo-anim 2s;
    }
}

@keyframes logo-anim {
    0% {
        // transform: rotate(0deg) scale(1);
        transform: rotate(720deg) scale(3.5);
    }
    90% {
        transform: rotate(0deg) scale(1);
    }
}