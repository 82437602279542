.daily-bonus {
    .bonus-card {
        background-color: #0C2575;
        border-radius: 15px;
        position: relative;
        margin-bottom: 20px;

        .day-info {
            background-color: #D5890E;
            position: absolute;
            top: -10px;
            font-size: 14px;
            border-radius: 20px;
            padding: 5px 10px;
        }

        .coins {
            width: 100%;
            height: 50px;
            position: relative;
            display: flex;
            justify-content: center;
            margin-top: 25px;
            position: relative;
        }
        .collection-container {
            bottom: -10px;
            button {
                background: linear-gradient(180deg, #C6CAD6, #E5EBFF, #BBBDC3);
                color: #818181;
                font-weight: bold;
                font-size: 14px;
            }
        }
        &.collected {
            opacity: 0.5;
        }
        &.collect {
            cursor: pointer;
        }
    }
}