.mp-social-tab-container{
    border-radius:0px;
    border-top-right-radius: 10px;
    scrollbar-width: none; 
    padding: 20px;
}
.mp-social-container{
    height:500px;
}

.mp-home-page-active{
    border-bottom:3px solid red !important;
    background:transparent !important;
    color:#000000 !important;
}