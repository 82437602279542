@mixin Popup {
    .m-popup {
        background-color: #2625254a;

        &.full-cover {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            z-index: 99;
            align-items: center;
            justify-content: center;
        }
    }

    .m-popup-body {
        min-width: 360px;
        max-width: 100% !important;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .m-popup-show {
        display: block;
    }

    .m-popup-hide {
        display: none;
    }

    .m-notification-popup {
        &.full-covered {
            position: fixed;
            left: auto;
            right: 10px;
            top: 55px;
            bottom: auto;
            display: flex;
            z-index: 99;
            align-items: center;
            justify-content: center;
        }
    }

    .popup-heading {
        padding: 10px 20px;
        // display: flex;
        position: relative;
        background-color: #d6c360;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        // border-bottom: 1px solid $skyler-color;

        .heading-label {
            padding-top: 30px;
            font-weight: 800;
            text-align: center;
        }

        .close-btn {
            // background-color: $lemon-color;
            position: absolute;
            right: 0;
            top: -40px;
            left: -4px;
            padding: 10px;
            color: white;
            width: 100%;
            display: flex;
            justify-content: center;
            img {
                border-radius: 50%;
                background-color: #d6c360;
            }
        }
    }

    .m-popup-content {
        margin-top: 20px;
        padding: 20px;
    }

    .m-actions {
        padding: 20px 20px;
        text-align: center;
    }

    .m-popup-header-bg {
        background-position: center;
        background-repeat: no-repeat;
        text-align: center;
        height: 170px;
        position: relative;
        top: 115px;
        z-index: 6;
    }

    .mp-popup-header-position {
        position: relative;
        top:-115px;
    }

    .mp-close-icon{
        cursor: pointer;
        z-index: 12;
        border-radius: 20px;
        top: -68px !important;
        left: 38%;
    }
}