.flick-drawer {
    position: absolute;
    left: 100%;
    width: 100%;

    &.smooth {
        transition: 0.2s;
    }
}

.flick-drawer-left {
    position: absolute;
    right: 100%;
    width: 100%;
    padding-bottom: 200px;
    overflow-y: auto;

    &.smooth {
        transition: 0.2s;
    }
}