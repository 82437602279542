.user-info {
    position: absolute;
    top: 65px;
    right: 5px;
    background-color: #eef1f2;
    z-index: 9;
    border-radius: 30px;
    overflow: hidden;
    animation: RightToLeft 0.4s;
    padding: 10px 30px ;
    .close-btn {
        position: absolute;
        right: 20px;
        top: 20px;
    }
    
    .nav-container {
        display: flex;
        flex-direction: column;
        
        a {
            // color: #ffffff;
            padding: 10px 60px;
            // padding: 20px 60px;
        }
    }

    .user-profile {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-bottom: 1px solid lightgray;
        padding-bottom: 10px;
    }
}