.leaderboard {
    .top-rankers {
        display: flex;
        align-items: end;
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 50px;

        .ranker {
            position: relative;
            width: 100px;
            border-top-right-radius: 30px;
            border-top-left-radius: 30px;
            // margin-left: 1px;
            // margin-right: 1px;

            .dp {
                position: absolute;
                left: 20px;
                top: -20px;
            }

            .player-info {
                padding: 50px 10px 10px 10px;
                word-break: break-all;
                font-size: 14px;
            }
            .rank-number {
                position: absolute;
                bottom: -50px;
                width: 100%;
                text-align: center;
                font-size: 25px;
            }
        }

        .rank-1 {
            height: 180px;
            background-color: #2174fa;

            // animation: rank-1 0.5s;
        }

        .rank-2 {
            height: 140px;
            background-color: #3a75d4;

            // animation: rank-2 0.5s;
        }

        .rank-3 {
            height: 120px;
            background-color: #2c589f;

            // animation: rank-3 0.5s;
        }
    }

    @keyframes rank-1 {
        0% {
            height: 0px;
        }

        100% {
            height: 200px;
        }
    }

    @keyframes rank-2 {
        0% {
            height: 0px;
        }

        100% {
            height: 150x;
        }
    }

    @keyframes rank-3 {
        0% {
            height: 0px;
        }

        100% {
            height: 120px;
        }
    }
}