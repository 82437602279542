.mr-alert {
    position: fixed;
    z-index: 999;
    width: 100%;
    padding: 10px 40px;
    top: 0px;
    transform: translate(0, -100%);
    transition: 0.3s;

    &.active {
        transform: translate(0, 10px);
    }

    .altert-content {
        background: linear-gradient(175deg, #28b7e9, #725bb6);
        border-radius: 50px;
        padding: 10px;
        text-align: center;
    }
}