.mp-follow-btn {
    width: 100px;
}

.btn {
    padding: 10px 45px;
    &:hover {
        background-color: #ff9e00;
        border-color: #ff9e00;
    }
}

// .btn-primary::after {
//     content: "";
//     background-color: #f3f3f357;
//     width: 100%;
//     display: block;
//     height: 50%;
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     border-radius: 50% 50% 0% 0%;
// }
.btn-primary {
    position: relative;
    background-color: #ff9e00;
    // box-shadow: 0 0 20px #eee;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border: none;
    &.active {
        background-color: #ff9e00;
    }


    // Game Style
    // overflow: hidden;
    // position: relative;
    // background-color: #ff9e00;
    // transition: 0.5s;
    // background-size: 200% auto;
    // color: white;
    // border: none;
    // box-shadow: 0 6px #d46a00;
}

.btn-sm {
    padding: 8px 25px;
}

.btn-secondary {
    border: none;
}

.btn-custom-olive {
    position: relative;
    background-color: #aea21f;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border: none;
    &.active {
        background-color: #aea21f;
    }
    &:hover {
        background-color: #746b07;
        border-color: #746b07;
        color: #ffffff;
    }
}

.btn-outline-primary {
    border-color: #ff9e00;
    color: #ff9e00;
    box-shadow: 0 0 5px #eee;
}

.btn-primary {
    // background: linear-gradient(180deg, #FFEDD0 4.5%, #FFC86E 18%, #FFAF2C 22.5%, #D5890E 69%, #8C5907 91%);
    // background-color: #FFAF2C;
    // color:#000000;
}


// transform: skew(-10deg, 0deg);
//     box-shadow: 3px 5px #4d543d;
//     font-weight: 900;
//     color: black;